
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ComponentSharedHeader, Enum_Componentsharedheader_Headlinecolor } from '~/graphql/generated'

@Component
export default class SharedHeader extends Vue {

  @Prop() readonly data!: ComponentSharedHeader

  get headlineTextColorClasses() {
    return this.data.headlineColor === Enum_Componentsharedheader_Headlinecolor.Light ? 'text-gray-700 dark:text-white lg:text-white' : this.data.headlineColor === Enum_Componentsharedheader_Headlinecolor.Dark ? 'text-gray-700 dark:text-gray-700' : 'text-gray-700 dark:text-white'
  }
}
