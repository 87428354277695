
import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { Enum_Componentsharedheader_Headlinetype } from '~/graphql/generated'

@Component
export default class Headline extends Vue {
  @Prop() readonly headerType: Enum_Componentsharedheader_Headlinetype

  get htmlType() {
    return this.headerType || Enum_Componentsharedheader_Headlinetype.H2
  }
}
