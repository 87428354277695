import { render, staticRenderFns } from "./TwoColumns.vue?vue&type=template&id=a100f93c&scoped=true&"
import script from "./TwoColumns.vue?vue&type=script&lang=ts&"
export * from "./TwoColumns.vue?vue&type=script&lang=ts&"
import style0 from "./TwoColumns.vue?vue&type=style&index=0&id=a100f93c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a100f93c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Decor: require('/vercel/path0/components/Decor.vue').default,SharedHeader: require('/vercel/path0/components/shared/Header.vue').default,SharedRichContent: require('/vercel/path0/components/shared/RichContent.vue').default,Container: require('/vercel/path0/components/Container.vue').default})
