
import { TweenMax } from 'gsap'
import {
  Component,
  Prop,
  Ref,
  Vue,
} from 'nuxt-property-decorator'
import { ComponentBlocksTwoColumns } from '~/graphql/generated'

@Component
export default class BlocksTwoColumns extends Vue {

  @Ref() readonly background!: HTMLDivElement

  @Prop() readonly data!: ComponentBlocksTwoColumns

  get sectionId(): string {
    return `${this.data.__typename}-${this.data.id}`
  }

  mounted() {
    if (!this.data.backgroundImage) {
      return
    }
    TweenMax.set(this.background, { y: '-12.5%' })
    const animateBackgroundImage = this.$scrollmagic
        .scene({
          triggerElement: `#${this.sectionId}`,
          duration: '200%',
          triggerHook: 1,
        })
        .setTween(this.background, 200, {
          y: '12.5%',
          ease: 'linear',
        })
    this.$scrollmagic.addScene(animateBackgroundImage)
  }
}
