import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=3d9a35ca&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeadlineLabel: require('/vercel/path0/components/HeadlineLabel.vue').default,Headline: require('/vercel/path0/components/Headline.vue').default,Header: require('/vercel/path0/components/Header.vue').default})
