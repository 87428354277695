

import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Decor extends Vue {
  @Prop({
    type: String,
    required: true,
    default: 'Dots1',
  })
  readonly name!: string

  @Prop({
    type: Number,
    required: false,
    default: 100,
  })
  readonly size!: number
}
